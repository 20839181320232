<template>
  <div>
    <a-table :columns="$store.state.user.username == 'admin' ? columns1: columns2" :data-source="items" size="small" :pagination="pagination" @change="tableChange" :loading="loading">
      <div slot="avg_retail_price" slot-scope="value">{{NP.round(value, 2)}}</div>
    </a-table>
  </div>
</template>

<script>
  import NP from 'number-precision'

  export default {
    name: 'GoodsPane',
    props: ['items', 'loading', 'pagination'],
    data() {
      return {
        NP,
        columns1: [

          {
            title: '产品名称',
            dataIndex: 'name',
            key: 'name',
          },

          {
            title: '出库总数量',
            dataIndex: 'sales_quantity',
            key: 'sales_quantity',
          },
          {
            title: '平均成本价',
            dataIndex: 'cost_price',
            key: 'cost_price',
            customRender: (value, item, index) => {
              return (NP.divide(item.stock_out_amount, item.sales_quantity)).toFixed(2)
             }
          },
          {
            title: '出库成本金额',
            dataIndex: 'stock_out_amount',
            key: 'stock_out_amount',
            customRender: (value, item, index) => {
              return item.stock_out_amount.toFixed(2)
             }

          },
          {
            title: '平均销售价',
            dataIndex: 'cost_price',
            key: 'cost_price',
            customRender: (value, item, index) => {
              return (NP.divide(item.total_amount, item.sales_quantity)).toFixed(2)
             }
          },
          {
            title: '销售金额',
            dataIndex: 'total_amount',
            key: 'total_amount',
            customRender: (value, item, index) => {
              return item.total_amount.toFixed(2)
             }

          }

        ],
      columns2: [
          {
            title: '产品名称',
            dataIndex: 'name',
            key: 'name',
          },

          {
            title: '出库总数量',
            dataIndex: 'sales_quantity',
            key: 'sales_quantity',
          },
          {
            title: '平均成本价',
            dataIndex: 'cost_price',
            key: 'cost_price',
            customRender: (value, item, index) => {
              return (NP.divide(item.stock_out_amount, item.sales_quantity)).toFixed(2)
             }
          },
          {
            title: '出库成本金额',
            dataIndex: 'stock_out_amount',
            key: 'stock_out_amount',
            customRender: (value, item, index) => {
              return item.stock_out_amount.toFixed(2)
             }

          },



        ],
      };
    },
    methods: {
      tableChange(pagination, filters, sorter) {
        this.$emit('tableChange', pagination, filters, sorter)
      },
    }
  }
</script>